body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.crop {
  width: 75px;
  height: 75px;
  overflow: hidden;
}

.crop img {
  width: 200px;
  height: 230px;
  margin: -5px 0 0 -67px;
}
.full-container {
  min-height: 90vh;
}
@media screen and (max-width: 768px) {
  #image,
  #img {
    display: none;
  }
}
/* .nav-item-custom:hover {
  color: aqua !important;
} */

.nav-item-custom {
  display: inline-block;
  position: relative;
}

.nav-item-custom::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 3px;
  bottom: 0;
  left: 0;
  background-color: aqua;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.nav-item-custom:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
